import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import BackArrow from "../assets/svg/arrow-back-white.svg"

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  h1 {
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 2em;
    font-weight: 800;
  }
  svg {
    margin: auto;
    cursor: pointer;
    padding: 40px;
    width: 150px;
    transition: all 1s ease;
    &:hover {
      width: 160px;
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Wrapper>
      <Link to="/">
        <BackArrow />
      </Link>
      <h1>Home</h1>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
